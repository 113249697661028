import React, { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import Img from '../../../Assets/Thumbnails/youtube thumbnail addition.png';
import Img2 from '../../../Assets/Thumbnails/division easy thumbnail.png';
import Img3 from '../../../Assets/Thumbnails/multiplication easy thumbnail.png';
import Img4 from '../../../Assets/Thumbnails/subtraction easy thumbnail.png';
import Img5 from '../../../Assets/Thumbnails/black sheep thumbnail.png';
import Img6 from '../../../Assets/Thumbnails/abc-thumbnail-(small).png';
import Img7 from '../../../Assets/Thumbnails/thumbnail 123.png';
import Img8 from '../../../Assets/Thumbnails/spelling_game_mdium_thumbnail.png';
import Img9 from '../../../Assets/Thumbnails/shape_puzzle_thumbnail.png';
import Img10 from '../../../Assets/Thumbnails/Match_making_easy_thumbnail.png';
import Img11 from '../../../Assets/Thumbnails/youtube thumbnail addition.png';

import youtubeEmoji from '../../../Assets/youtube-icon.png';

import { useWindowSize } from "../../Common/Common";
import './YoutubeContent.less';

import previousImg from '../../../Assets/previous.png';
import NextImg from '../../../Assets/next.png';

const youtubeData = [
  // {
  //   key: 1,
  //   youtubeThumbnail: Img,
  //   heading: 'Basic Addition For Kids',
  //   description: 'Easy Addition E-learning Game',
  //   youtubeEmoji: youtubeEmoji,
  //   videoUrl: 'https://www.youtube.com/watch?v=er-h4d5X6kw'
  // },
  // {
  //   key: 2,
  //   youtubeThumbnail: Img2,
  //   heading: 'Basic Division For Kids',
  //   description: 'Easy Division E-learning Game',
  //   youtubeEmoji: youtubeEmoji,
  //   videoUrl: 'https://www.youtube.com/watch?v=s-5wEu2hCvA'
  // },
 
  {
    key: 5,
    youtubeThumbnail: Img5,
    heading: 'Nursery Rhymes For Kids',
    description: 'Baa Baa Black Sheep Poem',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=d01ONZqZIx8'
  },
  {
    key: 6,
    youtubeThumbnail: Img6,
    heading: 'ABC Writing For Kids',
    description: 'Learn To Write a-z English Letters',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=pHHxFX0fN0I&t=3s'
  },
  {
    key: 7,
    youtubeThumbnail: Img7,
    heading: 'Write Numbers 1-50',
    description: 'Learn To Form Numbers 123',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=p0H2zogICZ4&t=7s'
  },
  {
    key: 8,
    youtubeThumbnail: Img8,
    heading: 'Learn To Spell Words',
    description: "Build Your Kid's Vocabulary",
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=nRkGdpEFfHs&t=11s'
  },
  {
    key: 9,
    youtubeThumbnail: Img9,
    heading: 'Shapes For Kids',
    description: 'Learn the Shapes of Different Objects ',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=4-rwSWS4aDk&t=35s'
  },
  {
    key: 10,
    youtubeThumbnail: Img10,
    heading: 'Matching & Logic Games',
    description: "Test Your Kid's Visual Memory",
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=KztuMNwNMT0'
  },
  {
    key: 11,
    youtubeThumbnail: Img11,
    heading: 'Addition Exercise for Kids',
    description: 'Learn To Add While Playing Games',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=er-h4d5X6kw&t=3s'
  },
  {
    key: 3,
    youtubeThumbnail: Img3,
    heading: 'Easy Multiplication For Kids ',
    description: 'Practice Basic Multiplication',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=4XHw1m3sRpY'
  },
  {
    key: 4,
    youtubeThumbnail: Img4,
    heading: 'Practice Subtraction',
    description: 'Fun Subtraction Game For Kids',
    youtubeEmoji: youtubeEmoji,
    videoUrl: 'https://www.youtube.com/watch?v=a0r6aGwuDBk'
  },
];

const YoutubePart = () => {
  const [width] = useWindowSize();
  const sliderRef = useRef(null);
  const [zoomedIndex, setZoomedIndex] = useState(0);

  const handlePrevClick = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      setZoomedIndex(sliderRef.current.swiper.realIndex);
    }
  }, []);

  return (
    <div className="youtube-container" id="youtubeLink">
      <div className="youtube-heading">
        EXPLORE OUR YOUTUBE
        <br />
        CHANNEL CONTENT
      </div>
      <div className="crouseldiv">
        <img src={previousImg} onClick={handlePrevClick} className="previous" alt="Previous" />
        <Swiper
         spaceBetween={55}
         slidesPerView={width > 768 ? 3 : 1} /* Adjust for screen size */
         centeredSlides={true}
         loop={true}
         ref={sliderRef}
         onSlideChange={(swiper) => setZoomedIndex(swiper.realIndex)}
        >
          {youtubeData.map((item, index) => (
            <SwiperSlide
              className={`youtube-card ${index === zoomedIndex ? 'zoomed' : ''}`}
              key={item.key}
            >
              <a href={item.videoUrl} target="_blank" rel="noopener noreferrer" className="youtubeThumbnail">
                <img src={item.youtubeThumbnail} alt="YouTube Thumbnail" />
              </a>
              <h3>{item.heading}</h3>
              <p>{item.description}</p>
              <a href={item.videoUrl} target="_blank" rel="noopener noreferrer" className="YouTube-symbol">
                <img src={item.youtubeEmoji} alt="YouTube Emoji" />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        <img src={NextImg} onClick={handleNextClick} className="next" alt="Next" />
      </div>
    </div>
  );
};

export default YoutubePart;
