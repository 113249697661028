// React component code

import React from 'react';
import './E-Learning.less'; // Make sure to import your CSS file
import playstore from '../../../Assets/Google play.png';
import bannerImage from '../../../Assets/banner.png'; // Import the banner image
import mobileBannerImg from '../../../Assets/mobile/banner cropped.png'
import appstoreImg from "../../../Assets/app store.png";

// import Adventure from './Adventure';

const ElearningComponent = () => {
  return (
    <div>
      {/* Desktop View */}
      <div className="image-container1">
        <img src={bannerImage} alt="Banner" className="banner-image" />
        <h1>E-Learning App For Kids</h1>
        <p className='para'>
          KidsKite is an educational app for kids aged 2-7 to build their early skills. It is a delightful <br />blend of fun and education where you can find kids learning games that help
          them to <br />establish their foundational skills.
        </p>
        <div className='playstoreButtonIMg'>
          <a href="https://play.google.com/store/apps/details?id=app.freakx.kidskite&pli=1" target="_blank" rel="noopener noreferrer">
            <img src={playstore} alt="Play Store" />
          </a>
          {/* Second Play Store Button */}
          <a href="https://apps.apple.com/in/app/kidskite-learning-games-2-7/id6689513093" target="_blank" rel="noopener noreferrer">
            <img src={appstoreImg} alt="App Store" />
          </a>
        </div>
        </div>

      {/* Mobile and Tab View */}
      <div className="image-container-mobile">
        <img src={mobileBannerImg} alt="Banner" className="banner-image-mobile" />
        <h1 className='h1-mobile'>E-Learning App <br/>For Kids</h1>
        <p className='para-mobile'>
          KidsKite is an educational app for kids aged 2-7 to build their early skills. It is a delightful blend of fun and education where you can find kids learning games that help
          them to &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;establish their foundational skills.
        </p>
        <div className='playstoreButtonIMg-mobile'>
          <a href="https://play.google.com/store/apps/details?id=app.freakx.kidskite&pli=1" target="_blank" rel="noopener noreferrer">
            <img src={playstore} alt="Play Store" />
          </a>
          <a href="https://apps.apple.com/in/app/kidskite-learning-games-2-7/id6689513093" target="_blank" rel="noopener noreferrer">
            <img src={appstoreImg} alt="App Store" />
          </a>
          
        </div>
      </div>
    </div>
  );
};

export default ElearningComponent;
