import React from "react";
import "./UnlockEndless.less";
import backgrounfImg from "../../../Assets/cta 1920X855.png";
import MobileBackgroundImg from "../../../Assets/mobile/unlock-endless-banner-x2.png";
// import SixthSection from "../../Component/HomePage/YoutubeContent"
import playstoreImg from "../../../Assets/Google play.png";
import appstoreImg from "../../../Assets/app store.png";

import { Col, Row } from "antd";

const FifthPage = () => {
  return (
    <div className="fifthPage_bg_Home" id="whykidskite">
      <Row>
        <Col>
          <img src={backgrounfImg} className="backgroundImage" />
        </Col>
        <Col className="mobileimagebanner">
          <img src={MobileBackgroundImg} className="backgroundImage_mobile" />
        </Col>
        <Col className="descriptionCol">
          <div className="fifth_Page_unlock" style={{ textAlign: "center" }}>
            UNLOCK THE ENDLESS PLAYFUL
          </div>
          <div
            className="fifth_Page_unlock_line"
            style={{ textAlign: "center" }}
          >
            LEARNING WITH KIDSKITE ACCESS!
          </div>
          <div className="description">
            KidsKite makes e-learning fun learning for kids. Play and learn with
            our extensive
            <br /> mix of kids educational games!
          </div>
          <div className="playStoreImg">
            <a
              href="https://play.google.com/store/apps/details?id=app.freakx.kidskite&pli=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playstoreImg} alt="imgage" />
              <a
                href="https://apps.apple.com/in/app/kidskite-learning-games-7/id6689513093"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appstoreImg} alt="App Store" />
              </a>
            </a>
            <p>Download the app now!</p>
          </div>
        </Col>

        <Col className="descriptionCol-mobile">
          <div className="fifth_Page_unlock-mobile">UNLOCK ENDLESS</div>
          <div className="fifth_Page_unlock_line-mobile">
            PLAYFUL LEARNING <br /> WITH KIDSKITE ACCESS!
          </div>
          <div className="description-mobile">
            KidsKite makes e-learning fun learning for kids. Play and learn with
            our extensive mix of kids
            <br /> educational games!
          </div>
          <div className="playStoreImg-mobile">
  <a
    href="https://play.google.com/store/apps/details?id=app.freakx.kidskite&pli=1"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={playstoreImg} alt="Google Play Store" />
  </a>
  <a
    href="https://apps.apple.com/in/app/kidskite-learning-games-7/id6689513093"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={appstoreImg} alt="App Store" />
  </a>
  <p>Download the app now!</p>
</div>

        </Col>
      </Row>
      {/* <SixthSection /> */}
    </div>
  );
};

export default FifthPage;
