// JSX code

import React, { useState, useEffect } from 'react';
import Form from './ContactForm';
import footerImg from '../../../Assets/footer updated.png';
import mobileFooterImg from '../../../Assets/mobile/footer-cropped.png'

import youtubeImg from '../../../Assets/youtube.png';
import instagramImg from '../../../Assets/instagram.png';
import facebookImg from '../../../Assets/facebook.png';
import playstoreImg from '../../../Assets/playstore.png';
import teddyDialogImg from '../../../Assets/dog with dialogue.png'
import DialogImgTeddy from '../../../Assets/teddy-with-dialogue.png'
import kattyDialogImg from '../../../Assets/catty_with_dialogue.png'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import appstoreImg from "../../../Assets/app store.png";


import './MobileFooter.less';


const Footer = () => {
    let navigate = useNavigate();


    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const teddyImages = [
        teddyDialogImg,
        DialogImgTeddy,
        kattyDialogImg,
        teddyDialogImg,
        DialogImgTeddy,
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % teddyImages.length);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [currentImageIndex, teddyImages]);

    return (
        <div className='footer' id = 'contact-us'>
            <img
                className='mobilefooterimg'
                src={mobileFooterImg}
                style={{ width: '100%', height: '100%' }}
            />
            <div className='footeronlyform'>
                <div className='mobile-form-container'>
                    <Form />
                </div>
            </div>
            <div className='footer-content'>

                <div className='mobile-para-container'>
                    <p className='mobileParaa'>
                        KidsKite is a learning app for kids aged 2-7, offering play-based
                        learning through educational games. The main mission is to turn your kids' screen time into smart time and make their early learning an exciting adventure.
                    </p>
                </div>
                <div className='playstore-img-mobile'>
                    <a href="https://play.google.com/store/apps/details?id=app.freakx.kidskite&pli=1" target="_blank" rel="noopener noreferrer">
                        <img
                            src={playstoreImg}
                            alt="Playstore"
                            className='playstore-mobile'
                        />
                    </a>
                    <a href="https://apps.apple.com/in/app/kidskite-learning-games-2-7/id6689513093" target="_blank" rel="noopener noreferrer">
                        <img
                            src={appstoreImg}
                            alt="Appstore"
                            className='appstore-mobile'
                        />
                    </a>
                </div>
                <div className='social-media'>
                    <a href="https://www.youtube.com/@KidsKiteLearning" target="_blank" rel="noopener noreferrer">
                        <img src={youtubeImg} alt="YouTube" className='social' style={{ width: '40px', height: '43px' }} />
                    </a>
                    <a href="https://www.facebook.com/kidskitelearning/" target="_blank" rel="noopener noreferrer">
                        <img src={facebookImg} alt="Facebook" className='social' style={{ width: '40px', height: '43px' }} />
                    </a>
                    <a href="https://www.instagram.com/kidskitelearning/" target="_blank" rel="noopener noreferrer">
                        <img src={instagramImg} alt="Instagram" className='social' style={{ width: '40px', height: '43px' }} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
